// The app will sort courses according to date and not show old courses.
// When a course is re-schedules all you need to do is to update the date.
export const courses = [

  {
    name: "Scalable AI on DelftBlue",
    desc: "This course teaches you how to scale up your machine learning workflows to large amounts of data on a supercomputer. DelftBlue is the supercomputer used in this iteration of the course.",
    link: "https://reit.pages.ewi.tudelft.nl/course-scalable-ai-101-on-delftblue",
    details_link:
      "https://gitlab.ewi.tudelft.nl/reit/course-scalable-ai-101-on-delftblue",
    date: "2025-06-12",
    time: "09:30-17:00",
    location: "Pinguin Lab (Building 36)",
  },
  {
    name: "DAIC 101",
    desc: "The Delft AI Cluster (DAIC), formerly INSY HPC or simply HPC, is a TU Delft High Performance Computing (HPC) cluster consisting of heterogenous Linux compute servers with a lot of processing power and memory for running large, long or GPU-enabled jobs. DAIC has been operational since 2015, and is available (only) to users from the participating departments for research purposes.",
    link: "https://reit.pages.ewi.tudelft.nl/course-daic-101",
    details_link: "https://gitlab.ewi.tudelft.nl/reit/course-daic-101",
    date: "2025-02-11",
    time: "13:30-17:30",
    location: "Social Data Lab (28.0.E220)",
  },
  {
    name: "Python Project Reproducibility & Automation",
    desc: "This course will teach you how to better package your Python code. This includes many best practices for the whole life cycle of the project. Including, how to package and publish your code, how to format and lint, strategies for good code architecture, documentation, dependency management, testing and more.",
    link: "https://reit.pages.ewi.tudelft.nl/course-python-project/",
    details_link:
      "https://gitlab.ewi.tudelft.nl/reit/course-python-project",
    date: "2025-05-15",
    time: "09:00-14:30",
    location: "Social Data Lab (28.0.E220)",
  },
  {
    name: "Containerization & DAIC",
    desc: "Containerization revolutionizes the way researchers manage and deploy applications by ensuring consistency across various computing environments, thus significantly reducing the “it works on my machine” problem. By joining this course, you’ll gain practical skills in using Apptainer, empowering you to enhance reproducibility, streamline workflows, and leverage the full potential of High-Performance Computing (HPC) for your research. It’s an essential step for any researcher looking to make their computational studies more efficient and shareable across diverse platforms.",
    link: "https://reit.pages.ewi.tudelft.nl/course-containerization-on-daic",
    details_link:
      "https://gitlab.ewi.tudelft.nl/reit/course-containerization-on-daic",
    date: "2025-03-04",
    time: "09:00-12:30",
    location: "Social Data Lab (28.0.E220)",
  },
  {
    name: "Rust for Research",
    desc: "In this course we will show you how you can use Rust to make high performance, reliable and most importantly correct software to use in your research. Giving you more guarantees than you might be used to normally. We’ll also show you how you can easily access the wealth of libraries in the Rust ecosystem to reduce your own workload.",
    link: "https://reit.pages.ewi.tudelft.nl/course-rust-basics",
    details_link: "https://gitlab.ewi.tudelft.nl/reit/course-rust-basics",
    date: "2025-04-08",
    time: "09:00-17:00",
    location: "Social Data Lab (28.0.E220)",
  },
  {
    name: "Scalable AI on DAIC",
    desc: "This course teaches you how to scale up your machine learning workflows to large amounts of data on a supercomputer. The Delft AI Cluster (DAIC) is the supercomputer used in this iteration of the course.",
    link: "https://reit.pages.ewi.tudelft.nl/course-scalable-ai-101-on-daic",
    details_link:
      "https://gitlab.ewi.tudelft.nl/reit/course-scalable-ai-101-on-daic",
    date: "2025-04-09",
    time: "09:30-17:00",
    location: "Social Data Lab (28.0.E220)",
  },

  // Past courses do NOT delete.

  {
    name: "DAIC 101",
    desc: "The Delft AI Cluster (DAIC), formerly INSY HPC or simply HPC, is a TU Delft High Performance Computing (HPC) cluster consisting of heterogenous Linux compute servers with a lot of processing power and memory for running large, long or GPU-enabled jobs. DAIC has been operational since 2015, and is available (only) to users from the participating departments for research purposes.",
    link: "https://reit.pages.ewi.tudelft.nl/course-daic-101",
    details_link: "https://gitlab.ewi.tudelft.nl/reit/course-daic-101",
    date: "2024-10-01",
    time: "13:30-18:00",
    location: "Social Data Lab (28.0.E220)",
  },
  {
    name: "Python Best Practices",
    desc: "This course will teach you how to manage your python code better. This includes many best practices for the whole life cycle of the project. Including, how to package and publish your code, how to format and lint, strategies for good code architecture, documentation, dependency management, testing and more.",
    link: "https://reit.pages.ewi.tudelft.nl/course-python-best-practices",
    details_link:
      "https://gitlab.ewi.tudelft.nl/reit/course-python-project",
    date: "2024-10-15",
    time: "13:00-17:30",
    location: "Social Data Lab (28.0.E220)",
  },
  {
    name: "Containerization & DAIC",
    desc: "Containerization revolutionizes the way researchers manage and deploy applications by ensuring consistency across various computing environments, thus significantly reducing the “it works on my machine” problem. By joining this course, you’ll gain practical skills in using Apptainer, empowering you to enhance reproducibility, streamline workflows, and leverage the full potential of High-Performance Computing (HPC) for your research. It’s an essential step for any researcher looking to make their computational studies more efficient and shareable across diverse platforms.",
    link: "https://reit.pages.ewi.tudelft.nl/course-containerization-on-daic",
    details_link:
      "https://gitlab.ewi.tudelft.nl/reit/course-containerization-on-daic",
    date: "2024-10-30",
    time: "09:00-14:00",
    location: "Social Data Lab (28.0.E220)",
  },
  {
    name: "Rust for Research",
    desc: "In this course we will show you how you can use Rust to make high performance, reliable and most importantly correct software to use in your research. Giving you more guarantees than you might be used to normally. We’ll also show you how you can easily access the wealth of libraries in the Rust ecosystem to reduce your own workload.",
    link: "https://reit.pages.ewi.tudelft.nl/course-rust-basics",
    details_link: "https://gitlab.ewi.tudelft.nl/reit/course-rust-basics",
    date: "2024-10-28",
    time: "09:30-17:00",
    location: "Social Data Lab (28.0.E220)",
  },
  {
    name: "Scalable AI on DAIC",
    desc: "This course teaches you how to scale up your machine learning workflows to large amounts of data on a supercomputer. The Delft AI Cluster (DAIC) is the supercomputer used in this iteration of the course.",
    link: "https://reit.pages.ewi.tudelft.nl/course-scalable-ai-101-on-daic",
    details_link:
      "https://gitlab.ewi.tudelft.nl/reit/course-scalable-ai-101-on-daic",
    date: "2024-11-12",
    time: "09:30-17:00",
    location: "Social Data Lab (28.0.E220)",
  },
  {
    name: "Advanced Rust",
    desc: "This courses covers a grabbag of techniques to improve the quality of the software you write in Rust. This course is primarily based on official and unofficial Rust idioms and code patterns that have been documented by the wider Rust community. We also discuss some of the standard and less standard tooling available to improve your development experience. You are expected to have basic familiarity with programming in Rust, including language concepts like structs, enums, functions, traits, the stack and heap memory model, and the borrow checker.",
    link: "https://reit.pages.ewi.tudelft.nl/course-advanced-rust",
    details_link: "https://gitlab.ewi.tudelft.nl/reit/course-advanced-rust",
    date: "2024-12-10",
    time: "14:00-18:00",
    location: "Social Data Lab (28.0.E220)",
  },
  {
    name: "Machine Learning using Python on DelftBlue",
    desc: "This course teaches you how to scale up your machine learning workflows to large amounts of data on a supercomputer. DelftBlue is the supercomputer used in this iteration of the course.",
    link: "https://www.tudelft.nl/cse/education/courses/machine-learning",
    details_link:
      "https://gitlab.ewi.tudelft.nl/reit/course-scalable-ai-101-on-delftblue",
    date: "2024-12-04",
    time: "09:30-16:30",
    location: "Pinguin Lab (Building 36)",
  },

  {
    name: "Containerization & DAIC",
    desc: "Containerization revolutionizes the way researchers manage and deploy applications by ensuring consistency across various computing environments, thus significantly reducing the “it works on my machine” problem. By joining this course, you’ll gain practical skills in using Apptainer, empowering you to enhance reproducibility, streamline workflows, and leverage the full potential of High-Performance Computing (HPC) for your research. It’s an essential step for any researcher looking to make their computational studies more efficient and shareable across diverse platforms.",
    link: "https://reit.pages.ewi.tudelft.nl/course-containerization-on-daic",
    details_link:
      "https://gitlab.ewi.tudelft.nl/reit/course-containerization-on-daic",
    date: "2024-06-26",
    time: "09:00-14:00",
    location: "Social Data Lab (28.0.E220)",
  },
  {
    name: "Scalable AI on DAIC",
    desc: "This course teaches you how to scale up your machine learning workflows to large amounts of data on a supercomputer. The Delft AI Cluster (DAIC) is the supercomputer used in this iteration of the course.",
    link: "https://reit.pages.ewi.tudelft.nl/course-scalable-ai-101-on-daic",
    details_link:
      "https://gitlab.ewi.tudelft.nl/reit/course-scalable-ai-101-on-daic",
    date: "2024-05-21",
    time: "09:00-17:00",
    location: "Social Data Lab (28.0.E220)",
  },
  {
    name: "DAIC 101",
    desc: "The Delft AI Cluster (DAIC), formerly INSY HPC or simply HPC, is a TU Delft High Performance Computing (HPC) cluster consisting of heterogenous Linux compute servers with a lot of processing power and memory for running large, long or GPU-enabled jobs. DAIC has been operational since 2015, and is available (only) to users from the participating departments for research purposes.",
    link: "https://reit.pages.ewi.tudelft.nl/course-daic-101",
    details_link: "https://gitlab.ewi.tudelft.nl/reit/course-daic-101",
    date: "2024-06-06",
    time: "09:30-14:00",
    location: "Social Data Lab (28.0.E220)",
  },
  {
    name: "Scalable AI on DelftBlue",
    desc: "This course teaches you how to scale up your machine learning workflows to large amounts of data on a supercomputer. DelftBlue is the supercomputer used in this iteration of the course.",
    link: "https://reit.pages.ewi.tudelft.nl/course-scalable-ai-101-on-daic",
    details_link:
      "https://gitlab.ewi.tudelft.nl/reit/course-scalable-ai-101-on-delftblue",
    date: "2024-04-10",
    time: "09:30-16:00",
    location: "Pinguin Lab (Building 36)",
  },
];
